




























































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
  },
})
export default class Payroll extends Vue {
  loading = false;
  public fields = [
    { key: 'code', sortable: true }, 
    { key: 'title', sortable: false }, 
    {key: 'days'}, {key: 'basic'}, { key: 'housing'}, {key: 'transportation'}, { key: 'other_allowance' }, 
    {key: 'telephone'}, { key: 'supervisory_allowance'},
    {key: 'total_monthly_allowance'}, {key: 'total_monthly_salary'},
    {key: 'gosi'}, { key: 'driving_allowance'}, {key: 'work_permit'},
    {key: 'food_allowance'}, { key: 'temp_allowance', label : 'Motivation Allowance'},  { key: 'temp_allowance'}, {key: 'work_permit'},{key: 'kjo'},
    {key: 'ot'},{key: 'absence'}, {key: 'gross_salary'},
    {key: 'non_monthly_addition'},{key: 'previous_month_ot'},
    {key: 'absent_dedallow'},{key: 'non_monthly_deduction'},
    {key: 'previous_month_absences'},{key: 'ca_trafficfines_deduction'},
    {key: 'placement_fees'},{key: 'bank_fee'},
    {key: 'net_salary'},
    // {key: 'total_NM_addition'},{key: 'total_NM_deduction'}
  ];
  public items: any = []
  public currentPage = 1;
  public import_file = null
  public import_file_response = '';
  params_date: any = {}
  import_params: any = {}
  create: any = {};
  ot: any = {};
  leave: any = {};
  late: any = {};
  public selected_items = [];
  // public selected_items = [];
  // public items = [];
  // public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  onFiltered(filteredItems: any) { }

  mounted() { 
  }

  get_data(params: any) {
    this.loading = true;
    HRMService.get_employee_payroll(params)
      .then((response) => {
        this.items = response.data.result;
        this.selected_items = this.items;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  create_payroll(params: any) {
    this.loading = true;
    HRMService.post_employee_payroll(params)
      .then((response) => {
        alert('Payroll Generated')
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  submit_import(params: any) {
    this.loading = true;
    HRMService.post_payroll_file(this.import_file, params)
      .then((response) => {
        console.log(response);
        this.import_file_response = response.data.message 
        alert('Import Successfull')
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
  }
  onRowClicked(item: any, index: any, event: any) {
    this.$router.push({ name: "Payroll-Create", params: { id: item.id.toString() } });
  }


}
